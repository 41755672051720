<template>
  <div>
    <div class="login">
      <div class="row">
        <div class="col-md-6 d-table col-content">
          <div class="d-table-cell align-middle content">
            <div class="title-brand">
              <img src="/img/logo.png" alt="logo.png" />
            </div>
            <div class="card">
              <div class="title-form">
                <div class="welcome-text">Buat Password Baru</div>
              </div>
              <form class="needs-validation mt-5" @submit.prevent="onSubmit">
                <div class="form-group">
                  <label class="mb-3">Password Baru</label>
                  <div class="position-relative" data-toggle="change_password">
                    <input
                      type="password"
                      id="password"
                      v-model="formData.password"
                      @keyup="checkForm('password')"
                      class="form-control"
                      placeholder="Password"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      :class="{ 'is-invalid': formError.password }"
                    />
                    <span
                      class="form-icon"
                      :class="{ 'is-error': formError && formError.password }"
                    >
                      <i
                        class="far fa-eye-slash"
                        v-on:click="togglePassword"
                        id="togglePassword"
                      ></i>
                    </span>
                  </div>
                  <div
                    class="form-error"
                    v-if="formError && formError.password"
                  >
                    {{ formError.password }}
                  </div>
                </div>
                <div class="form-group mt-4">
                  <label class="mb-3">Ulang Password</label>
                  <div class="position-relative" data-toggle="change_password">
                    <input
                      type="password"
                      id="password-repeat"
                      v-model="formData.password_repeat"
                      @keyup="checkForm('password_repeat')"
                      class="form-control"
                      placeholder="Password"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      :class="{ 'is-invalid': formError.password_repeat }"
                    />
                    <span
                      class="form-icon"
                      :class="{
                        'is-error': formError && formError.password_repeat,
                      }"
                    >
                      <i
                        class="far fa-eye-slash"
                        v-on:click="togglePassword2"
                        id="togglePassword2"
                      ></i>
                    </span>
                  </div>
                  <div
                    class="form-error"
                    v-if="formError && formError.password_repeat"
                  >
                    {{ formError.password_repeat }}
                  </div>
                </div>
                <button
                  class="btn btn-form mb-4 mt-4 d-block"
                  id="submitForm"
                  :disabled="isSubmit"
                >
                  <i class="fas fa-spinner fa-spin" v-if="isSubmit"></i>
                  <span class="btn-login">Simpan</span>
                </button>
              </form>
            </div>
          </div>
        </div>
        <div class="col-md-6 text-center d-table col-banner">
          <div class="banner d-table-cell align-middle">
            <img
              src="../assets/img/img-banner2.svg"
              alt=""
              class="screen-img"
            />
            <img src="../assets/img/elipse1.svg" alt="" class="elipse-top" />
            <img src="../assets/img/elipse2.svg" alt="" class="elipse-bottom" />
            <img src="../assets/img/square.svg" alt="" class="square2" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import carousel from 'vue-owl-carousel2'

import $ from "jquery";
import { checkRules } from "../helper";
import { post_UpdatePassword } from "../actions/auth";

export default {
  name: "updatePassword",
  data() {
    return {
      formData: {
        password: "",
        password_repeat: "",
        access_token: localStorage.getItem("_AcTo"),
        email: localStorage.getItem("_Fem"),
      },
      formError: [],
      rules: {
        password: {
          required: true,
          minLength: 8,
          msg: "Password wajib diisi tidak boleh kosong",
          msg_minLength: "Minimal panjang password 8 karakter",
        },
        password_repeat: {
          equalTo: "password",
          msg_equal: "Password tidak sama",
        },
      },
      isSubmit: false,
    };
  },
  methods: {
    togglePassword() {
      const togglePassword = $("#togglePassword");
      const password = $("#password");
      const type = password.attr("type") === "password" ? "text" : "password";
      password.attr("type", type);
      // toggle the eye / eye slash icon
      togglePassword.toggleClass("fa-eye");
      togglePassword.toggleClass("fa-eye-slash");
    },

    togglePassword2() {
      const togglePassword = $("#togglePassword2");
      const password = $("#password-repeat");
      const type = password.attr("type") === "password" ? "text" : "password";
      password.attr("type", type);
      // toggle the eye / eye slash icon
      togglePassword.toggleClass("fa-eye");
      togglePassword.toggleClass("fa-eye-slash");
    },

    checkForm: async function (tipe) {
      this.rules[tipe].changed = true;
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
    },
    showWarningAlert(msg = "", callaback) {
      var _msg = msg ? msg : "Terjadi kesalahan, silakan ulangi kembali";
      this.$swal({
        html:
          '<div style="color:#222222; font-size:20px; font-weight:500;" class="mt-3 mb-3">GAGAL!</div>' +
          '<div style="color:#263238" font-size:12px;">' +
          _msg +
          "</div>",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonColor: "#fff",
        cancelButtonText: "Tutup",
      }).then(function () {
        if (typeof callaback === "function") {
          callaback();
        }
      });
    },
    showAlert(title = "", msg, callaback) {
      var _msg = msg ? msg : "Password berhasil diperbarui";
      // Use swtalert2
      // Kode OTP terkirim ke nomor telepon <span style="color:#0E867F"> 0822******09 </span> mohon cek kotak masuk
      this.$swal({
        html:
          '<div style="color:#222222; font-size:20px; font-weight:500;" class="mt-3 mb-3">' +
          title +
          "</div>" +
          '<div style="color:#263238" font-size:12px;">' +
          _msg +
          "</div>",
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonColor: "#fff",
        confirmButtonText: "Tutup",
      }).then(function () {
        if (typeof callaback === "function") {
          callaback();
        }
        // window.location = "/verifikasi";
      });
    },
    onSubmit: async function () {
      for (const key in this.rules) {
        if (this.rules[key]) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        this.isSubmit = true;
        await post_UpdatePassword(
          this.formData,
          () => {
            this.isSubmit = false;
            this.showAlert("Berhasil!", "Password berhasil diperbarui", () => {
              localStorage.removeItem("_Fem");
              localStorage.removeItem("_AcTo");
              this.$router.push({ name: "login" });
            });
          },
          () => {
            this.isSubmit = false;
            this.showWarningAlert();
          }
        );
      }
    },
  },
};
</script>

<style scoped>
/* CSS only for examples not required for centering */

.login .title-form {
  margin-top: 35px;
}

.title-brand {
  font-size: 54px;
  color: #40ddd4;
  font-weight: bold;
  position: absolute;
  top: 2%;
  left: 10%;
}

.title-form .welcome-text {
  font-size: 28px;
  color: var(--text);
  font-weight: 600;
  text-align: center;
}

.title-form .desc-text {
  color: var(--text-light);
  font-size: 14px;
  font-weight: 300;
  margin-top: 10px;
}

.login .banner {
  background: linear-gradient(103.2deg, #6ec0fe 0%, #4df3dd 100%);
  height: 100vh;
  position: relative;
}

.login .img-logo {
  margin-top: 20px;
}

.login .banner .screen-img {
  height: 490px;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 2;
}

.login .content {
  padding: 38px 140px;
}

.login .d-table {
  padding: 0px !important;
}

.login .elipse-top {
  position: absolute;
  top: 0;
  left: 0;
}

.login .elipse-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
}

.login .square {
  position: absolute;
  top: 64px;
  left: 137px;
  width: 150px;
  height: 150px;
}

.login .square2 {
  position: absolute;
  bottom: 206px;
  right: 288px;
}

.register .square {
  position: absolute;
  top: 48px;
  left: 100px;
}

.register .square2 {
  position: absolute;
  bottom: 197px;
  right: 121px;
}

.banner .owl-theme .owl-dots .owl-dot span {
  background: #ffff;
  width: 6px;
  height: 6px;
}

.banner .owl-theme .owl-dots .owl-dot.active span {
  background: #fff;
  height: 6px;
  width: 30px;
}

/* show border around full height container */

.btn.btn-outline-dark {
  border: 2px solid #32cfb0;
  color: white;
  font-weight: normal;
  border-radius: 0;
}

.btn.btn-outline-dark:hover,
.btn.btn-outline-dark:active,
.btn.btn-outline-dark:focus,
.btn.btn-outline-dark:visited {
  background-color: #222222;
  border-color: #222222;
  outline: unset;
}

.btn-success-custom {
  background: #32cfb0;
  color: white;
  font-size: 13px;
  padding: 10px 26px;
  border-radius: 0;
}

.btn-success-custom:hover:not(:disabled),
.btn-success-custom:focus:not(:disabled),
.btn-success-custom:active:not(:disabled) {
  background-color: #222222;
  color: white;
}

.btn-success-custom:disabled,
.btn-success-custom:disabled:hover {
  background: #636363 !important;
  color: white;
}

.login .btn:hover {
  color: #fff !important;
}

.form-group {
  position: relative;
}

.login .form-group label {
  font-weight: 500;
  font-size: 14px;
  color: var(--text);
}

.login .form-group .form-control {
  color: var(--text);
  border: 1px solid #c4c4c4;
  padding: 9px 16px;
  font-family: "Poppins", sans-serif;
}

.form-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  cursor: pointer;
}

.form-icon.is-error {
  right: 30px;
}

.btn-form {
  background-color: #40ddd4;
  width: 100%;
  color: #fff;
  font-size: 16px;
  padding: 10px 10px;
  font-weight: 600;
}

.text-link a {
  text-decoration: underline;
  font-size: 14px;
}

.text-link {
  font-size: 14px;
}

.login .text-banner {
  color: #fff;
  margin-top: 30px;
}

.login .text-banner .title {
  font-size: 24px;
}

.login .text-banner .desc-title {
  font-size: 16px;
  color: #dcdada;
  font-weight: 300;
  margin-top: 20px;
  padding: 0px 100px;
}

/* Modal */

.modal-email .btn {
  background-color: #32cfb0;
  color: white;
  padding: 5px 20px;
}

.modal-email {
  padding: 0px 50px;
}

.modal-email span {
  color: #adadad;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 300;
}

.modal-email svg {
  width: 60px;
  margin: auto;
}

.login .card {
  border: none;
}

.input-group-text {
  background-color: #fff;
  border-right: none;
  border-color: #c4c4c4;
}

/* Carousel */

.owl-carousel {
  position: absolute;
  bottom: 38px;
}

/* Register */

.register .banner .screen-img {
  width: 400px;
  height: 400px;
}

/* Media Query */
@media (min-width: 768px) and (max-width: 900px) {
  .login .content {
    padding: 0px 90px;
  }
}

@media (max-width: 768px) {
  .login .col-banner {
    display: none !important;
  }

  .login .col-content {
    background-color: #32d2a2;
  }

  .login .content {
    padding: 0px 10px;
  }

  .login .card {
    margin: 60px !important;
    padding: 20px !important;
  }
}

@media (max-width: 467px) {
  .forgot-password .login-title h3 {
    font-size: 19px;
  }
}
</style>
